// src/components/Navbar.js
import React, { useState } from "react";
import LanguageSwitcher from "./LanguageSwitcher";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaSignOutAlt } from "react-icons/fa";
import { removeAuthToken } from "../services/auth";

const Navbar = ({ title, showBackButton = true, showLogoutButton = true }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

  const handleLogout = () => {
    removeAuthToken();
    navigate("/login");
  };

  return (
    <>
      <nav className="bg-gradient-to-r from-gray-800 to-gray-900 text-white p-4 flex items-center justify-between shadow-lg">
        <div className="flex items-center">
          {showBackButton && (
            <FaArrowLeft
              className="mr-4 cursor-pointer hover:text-gray-300 transition-colors duration-200"
              onClick={() => navigate("/projects")}
            />
          )}
          <img src="/general_logo512.png" alt="Logo" className="h-8 mr-2" />
          <h1 className="text-xl font-bold">{title}</h1>
        </div>
        <div className="flex items-center justify-end space-x-4">
          <LanguageSwitcher />
          {showLogoutButton && (
            <button
              onClick={() => setShowLogoutConfirm(true)}
              className="text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50 rounded-full p-2 transition-all duration-200 ease-in-out transform hover:scale-110"
              title={t("login.logout")}
            >
              <FaSignOutAlt className="w-5 h-5" />
            </button>
          )}
        </div>
      </nav>

      {showLogoutConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 max-w-sm w-full mx-4">
            <h2 className="text-xl font-bold mb-4">
              {t("login.logoutConfirmTitle")}
            </h2>
            <p className="mb-6">{t("login.logoutConfirmMessage")}</p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowLogoutConfirm(false)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 transition-colors"
              >
                {t("common.cancel")}
              </button>
              <button
                onClick={handleLogout}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
              >
                {t("login.logout")}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
