import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { NotificationProvider } from "./context/NotificationContext";
import Login from "./pages/Login";
import Projects from "./pages/projects/index";
import PrivateRoute from "./components/PrivateRoute";
import Layout from "./components/Layout";

// Import your project components
import HerdCounting from "./pages/projects/HerdCounting";
import GhostScale from "./pages/projects/GhostScale";
import EstrusDetection from "./pages/projects/EstrusDetection";
import HealthMonitoring from "./pages/projects/HealthMonitoring";

function App() {
  return (
    <NotificationProvider>
      <Router>
        <Layout>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Navigate to="/projects" replace />} />
            <Route
              path="/projects"
              element={
                <PrivateRoute>
                  <Outlet />
                </PrivateRoute>
              }
            >
              <Route index element={<Projects />} />
              <Route path="herd-counting" element={<HerdCounting />} />
              <Route path="ghost-scale" element={<GhostScale />} />
              <Route path="estrus-detection" element={<EstrusDetection />} />
              <Route path="health-monitoring" element={<HealthMonitoring />} />
            </Route>
          </Routes>
        </Layout>
      </Router>
    </NotificationProvider>
  );
}

export default App;
