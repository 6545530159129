import React from "react";
import Notification from "./Notification";

const NotificationContainer = ({ notifications, removeNotification }) => {
  const getPositionClasses = (position) => {
    const baseClasses = "fixed z-50 pointer-events-auto";

    switch (position) {
      case "top":
        return `${baseClasses} top-0 left-1/2 -translate-x-1/2 w-full px-4 sm:px-6 md:px-8`;
      case "bottom":
        return `${baseClasses} bottom-0 left-1/2 -translate-x-1/2 w-full px-4 sm:px-6 md:px-8`;
      case "left":
        return `${baseClasses} top-0 left-0 w-full px-4 sm:px-6 md:px-8 sm:top-1/2 sm:-translate-y-1/2 sm:w-auto sm:max-w-sm`;
      case "right":
        return `${baseClasses} top-0 left-0 w-full px-4 sm:px-6 md:px-8 sm:top-1/2 sm:-translate-y-1/2 sm:left-auto sm:right-0 sm:w-auto sm:max-w-sm`;
      default:
        return `${baseClasses} top-0 right-0 w-full px-4 sm:px-6 md:px-8 sm:w-auto sm:max-w-sm`;
    }
  };

  return (
    <>
      {notifications.map((notification) => (
        <div
          key={notification.id}
          className={getPositionClasses(notification.position)}
        >
          <div className="my-4 w-full sm:w-auto">
            <Notification
              notification={notification}
              onClose={() => removeNotification(notification.id)}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default NotificationContainer;
