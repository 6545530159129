// src/components/VideoModal.js
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  FaTimes,
  FaExclamationTriangle,
  FaCheckCircle,
  FaExclamationCircle,
} from "react-icons/fa";

const VideoModal = ({ videoUrl, notes, onClose }) => {
  const { t } = useTranslation();
  const videoRef = useRef(null);

  const getIconForNoteType = (type) => {
    switch (type) {
      case "warning":
        return <FaExclamationTriangle className="text-yellow-500" />;
      case "success":
        return <FaCheckCircle className="text-green-500" />;
      case "error":
        return <FaExclamationCircle className="text-red-500" />;
      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white rounded-lg overflow-hidden shadow-xl max-w-4xl w-full max-h-[90vh] flex flex-col">
        <div className="p-4 bg-gray-100 flex justify-between items-center">
          <h2 className="text-xl font-semibold">Video</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <FaTimes />
          </button>
        </div>
        <div className="p-4 overflow-y-auto flex-grow">
          <div
            className="relative"
            style={{ width: "640px", height: "480px", margin: "0 auto" }}
          >
            <video
              ref={videoRef}
              src={videoUrl}
              controls
              className="w-full h-full object-contain"
            />
          </div>
          {notes && notes.length > 0 && (
            <div className="mt-4 bg-gray-50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold mb-2">
                {t("herdCounting.notes")}
              </h3>
              {notes.map((note, index) => (
                <div key={index} className="flex items-start mb-2">
                  <div className="mr-2 mt-1">
                    {getIconForNoteType(note.type)}
                  </div>
                  <p
                    className={`text-${
                      note.type === "error"
                        ? "red"
                        : note.type === "warning"
                        ? "yellow"
                        : "green"
                    }-700`}
                  >
                    {note.message}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default VideoModal;
