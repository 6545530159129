import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import aeTranslations from "./ae.json";
import azTranslations from "./az.json";
import deTranslations from "./de.json";
import enTranslations from "./en.json";
import esTranslations from "./es.json";
import trTranslations from "./tr.json";
import cnTranslations from "./cn.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      ae: { translation: aeTranslations },
      az: { translation: azTranslations },
      de: { translation: deTranslations },
      es: { translation: esTranslations },
      en: { translation: enTranslations },
      tr: { translation: trTranslations },
      cn: { translation: cnTranslations },
    },
    fallbackLng: "en",
    detection: {
      order: ["cookie", "navigator", "htmlTag", "path", "subdomain"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
