import React, { useState } from "react";
import { FaLock, FaUnlock } from "react-icons/fa";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ProjectCard = ({ project, isPurchased, onClick }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  return (
    <div
      onClick={onClick}
      className={`relative overflow-hidden rounded-lg cursor-pointer group h-full ${
        isPurchased ? "bg-white" : "bg-gray-300"
      } min-h-[250px]`}
    >
      <div className="absolute inset-0">
        <LazyLoadImage
          src={project.backgroundImage}
          alt={project.name}
          effect="blur"
          afterLoad={() => setIsLoaded(true)}
          wrapperClassName="w-full h-full"
          className={`w-full h-full object-cover transition-all ease-in-out transform ${
            !isPurchased && "grayscale"
          } group-hover:scale-110`}
          style={{ transition: "ease .4s transform" }}
        />
      </div>
      {!isLoaded && (
        <div className="absolute inset-0 bg-gray-200 animate-pulse"></div>
      )}
      <div
        className={`absolute inset-0 ${
          isPurchased ? "bg-black bg-opacity-50" : "bg-gray-700 bg-opacity-80"
        } flex flex-col justify-end p-4 sm:p-6 transition-all duration-300 ease-in-out group-hover:bg-opacity-70`}
      >
        <h3
          className={`text-xl sm:text-2xl font-semibold mb-2 sm:mb-3 ${
            isPurchased ? "text-white" : "text-gray-300"
          } transition-all duration-300 ease-in-out group-hover:transform group-hover:translate-y-1`}
        >
          {project.name}
        </h3>
        <p
          className={`text-sm sm:text-base mb-2 sm:mb-4 ${
            isPurchased ? "text-gray-200" : "text-gray-400"
          } transition-all duration-300 ease-in-out group-hover:opacity-100`}
        >
          {project.description}
        </p>
        {isPurchased ? (
          <div className="absolute top-2 right-2 sm:top-4 sm:right-4 bg-green-500 text-white text-base sm:text-lg font-bold p-1 sm:p-2 rounded-full transition-all duration-300 ease-in-out group-hover:scale-110">
            <FaUnlock />
          </div>
        ) : (
          <div className="absolute top-2 right-2 sm:top-4 sm:right-4 bg-red-500 text-white text-base sm:text-lg font-bold p-1 sm:p-2 rounded-full transition-all duration-300 ease-in-out group-hover:scale-110">
            <FaLock />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectCard;
