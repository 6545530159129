import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { fetchPurchasedProjects } from "../../services/api";
import ProjectCard from "../../components/ProjectCard";
import { useNotification } from "../../context/NotificationContext";
import { Outlet } from "react-router-dom";

const Projects = () => {
  const { t } = useTranslation();
  const [purchasedProjects, setPurchasedProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { addNotification } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const projectIds = await fetchPurchasedProjects();
        setPurchasedProjects(projectIds);
      } catch (error) {
        console.error("Failed to fetch purchased projects:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, []);

  // Hard-coded project data
  const projectsData = [
    {
      id: 1,
      navigate: "herd-counting",
      name: t("projects.herdCounting.title"),
      description: t("projects.herdCounting.description"),
      backgroundImage:
        "https://static.wixstatic.com/media/571396_e8962b0607ce420cb5fde8c9bb447b79~mv2.jpg/v1/fill/w_839,h_709,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/571396_e8962b0607ce420cb5fde8c9bb447b79~mv2.jpg",
    },
    {
      id: 2,
      navigate: "ghost-scale",
      name: t("projects.ghostScale.title"),
      description: t("projects.ghostScale.description"),
      backgroundImage:
        "https://static.wixstatic.com/media/571396_795294b72e0e4aeab74d4e6e8ac12e3b~mv2.jpg/v1/fill/w_839,h_749,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/571396_795294b72e0e4aeab74d4e6e8ac12e3b~mv2.jpg",
    },
    {
      id: 3,
      navigate: "estrus-detection",
      name: t("projects.estrusDetection.title"),
      description: t("projects.estrusDetection.description"),
      backgroundImage:
        "https://static.wixstatic.com/media/571396_06b6d4c9ff4d491db6cc7f635b71e54b~mv2.jpg/v1/fill/w_839,h_693,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/571396_06b6d4c9ff4d491db6cc7f635b71e54b~mv2.jpg",
    },
    {
      id: 4,
      navigate: "health-monitoring",
      name: t("projects.healthMonitoring.title"),
      description: t("projects.healthMonitoring.description"),
      backgroundImage:
        "https://upload.wikimedia.org/wikipedia/commons/8/8c/Cow_%28Fleckvieh_breed%29_Oeschinensee_Slaunger_2009-07-07.jpg",
    },
    {
      id: 5,
      navigate: "",
      name: t("projects.comingSoon.title"),
      description: t("projects.comingSoon.description"),
    },
    {
      id: 6,
      navigate: "",
      name: t("projects.comingSoon.title"),
      description: t("projects.comingSoon.description"),
    },
  ];

  const handleProjectClick = (project) => {
    if (!purchasedProjects.includes(project.id)) {
      addNotification({
        type: "error",
        title: t("projects.notPurchased.title"),
        message: t("projects.notPurchased.message"),
        position: "bottom",
        timeout: 5000,
      });
    } else {
      navigate(`/projects/${project.navigate}`);
    }
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  // Ensure purchasedProjects is defined
  const purchasedProjectsList = purchasedProjects || [];

  // Sort projects so that purchased projects come first
  const sortedProjects = [
    ...projectsData.filter((project) =>
      purchasedProjectsList.includes(project.id)
    ),
    ...projectsData.filter(
      (project) => !purchasedProjectsList.includes(project.id)
    ),
  ];

  return (
    <div className="bg-gray-50">
      <Navbar title={t("projects.title")} showBackButton={false} />
      <div className="container mx-auto px-4 py-4" style={{ height: "100vh" }}>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-5 gap-y-3 h-full overflow-y-auto">
          {" "}
          {/* Separate horizontal and vertical gap */}
          {sortedProjects.map((project) => (
            <ProjectCard
              key={project.id}
              project={project}
              isPurchased={purchasedProjectsList.includes(project.id)}
              onClick={() => handleProjectClick(project)}
            />
          ))}
        </div>
        <Outlet />
      </div>
    </div>
  );
};

export default Projects;
