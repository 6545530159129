import React from "react";

function Layout({ children }) {
  return (
    <div className="min-h-screen flex flex-col relative">
      <main className="flex-grow">{children}</main>
    </div>
  );
}

export default Layout;
