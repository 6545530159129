import React, { createContext, useContext, useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import NotificationContainer from "../components/NotificationContainer";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const removeNotification = useCallback((id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.id !== id)
    );
  }, []);

  const addNotification = useCallback(
    (notification) => {
      const id = uuidv4();
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        { ...notification, id },
      ]);

      if (notification.timeout) {
        setTimeout(() => {
          removeNotification(id);
        }, notification.timeout);
      }
    },
    [removeNotification]
  );

  return (
    <NotificationContext.Provider
      value={{ addNotification, removeNotification }}
    >
      {children}
      <NotificationContainer
        notifications={notifications}
        removeNotification={removeNotification}
      />
    </NotificationContext.Provider>
  );
};
