import React, { useState, useEffect, useRef, useCallback } from "react";
import { FaPlay, FaSortAmountDown, FaSortAmountUp } from "react-icons/fa";
import { format, formatDistanceToNow, isAfter, subHours } from "date-fns";
import { useTranslation } from "react-i18next";
import { enUS, tr } from "date-fns/locale";
import { fetchFarms, fetchCounts } from "../../services/api";
import VideoModal from "../../components/VideoModal";
import SkeletonRow from "../../components/SkeletonRow";
import Navbar from "../../components/Navbar";
import axios from "axios";
import { getAuthToken } from "../../services/auth";
import { useNotification } from "../../context/NotificationContext";
import { API_BASE_URL } from "../../services/api.js";

const HerdTrackingDetail = () => {
  const { t, i18n } = useTranslation();
  const [counts, setCounts] = useState([]);
  const [farms, setFarms] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState("all");
  const [sortField, setSortField] = useState("date");
  const [sortDirection, setSortDirection] = useState("desc");
  const [showVideo, setShowVideo] = useState(false);
  const [currentNotes, setCurrentNotes] = useState([]);
  const [currentVideo, setCurrentVideo] = useState("");
  const [videoUrlCache, setVideoUrlCache] = useState({});
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const observer = useRef();
  const dateLocale = i18n.language.startsWith("tr") ? tr : enUS;
  const { addNotification } = useNotification();

  const lastCountElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      try {
        const [farmsData, countsData] = await Promise.all([
          fetchFarms(),
          fetchCounts(
            1,
            sortField,
            sortDirection,
            selectedFarm === "all" ? null : selectedFarm
          ),
        ]);
        setFarms(farmsData);
        setCounts(countsData.counts);
        setHasMore(countsData.hasMore);
      } catch (error) {
        console.error("Error loading initial data:", error);
      }
      setLoading(false);
    };

    loadInitialData();
  }, [sortField, sortDirection, selectedFarm]);

  useEffect(() => {
    const loadMoreCounts = async () => {
      if (page === 1) return;
      setLoading(true);
      try {
        const newCountsData = await fetchCounts(
          page,
          sortField,
          sortDirection,
          selectedFarm === "all" ? null : selectedFarm
        );
        setCounts((prevCounts) => [...prevCounts, ...newCountsData.counts]);
        setHasMore(newCountsData.hasMore);
      } catch (error) {
        console.error("Error loading more counts:", error);
      }
      setLoading(false);
    };

    loadMoreCounts();
  }, [page, sortField, sortDirection, selectedFarm]);

  const handleSort = (field) => {
    setSortField(field);
    setSortDirection((prev) => (prev === "asc" ? "desc" : "asc"));
    setCounts([]);
    setPage(1);
    setHasMore(true);
  };

  const handleFarmChange = (farmId) => {
    if (farmId === selectedFarm) return;

    setSelectedFarm(farmId);
    setCounts([]);
    setPage(1);
    setHasMore(true);
  };

  const fetchVideoUrl = async (countId) => {
    const token = getAuthToken();
    const response = await axios.get(`${API_BASE_URL}/video/${countId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const handleWatchClick = async (count) => {
    if (count.videoKey === null) {
      addNotification({
        type: "info",
        message: t("herdCounting.videoPreparingMessage"),
        position: "top",
        timeout: 5000,
      });
      return;
    }

    let videoUrl = videoUrlCache[count._id];

    if (!videoUrl) {
      try {
        const response = await fetchVideoUrl(count._id);
        videoUrl = response.url;
        setVideoUrlCache((prev) => ({ ...prev, [count._id]: videoUrl }));
      } catch (error) {
        console.error("Error fetching video URL:", error);
        addNotification({
          type: "error",
          message: t("herdCounting.videoLoadError"),
          position: "top",
          timeout: 5000,
        });
        return;
      }
    }

    setCurrentVideo(videoUrl);
    setCurrentNotes(count.notes || []);
    setShowVideo(true);
  };

  const renderAnimalCount = (animals) => {
    return Object.entries(animals).map(([key, value]) => (
      <div key={key} className="text-sm">
        {t(`animals.${key.toLowerCase()}`)}: {value}
      </div>
    ));
  };

  const getFarmName = (farmId) => {
    const farm = farms.find((f) => f.id === farmId);
    return farm ? farm.name : t("common.unknown");
  };

  const isNew = (date) => {
    return isAfter(new Date(date), subHours(new Date(), 12));
  };

  const getCountingDirection = (direction, farmId) => {
    const farmData = farms.find((f) => f.id === farmId);
    const directionKey = farmData?.countingDirections?.[direction];
    if (directionKey === undefined) return t("common.unknown");

    return directionKey === 0 ? (
      <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
        {t("herdCounting.exit")}
      </span>
    ) : (
      <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
        {t("herdCounting.enter")}
      </span>
    );
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <Navbar title={t("herdCounting.title")} />

      <main className="container mx-auto p-4">
        {farms.length > 1 && (
          <div className="mb-6">
            <h2 className="text-lg font-semibold mb-2 text-gray-700">
              {t("herdCounting.myFarms")}
            </h2>
            <div className="flex flex-wrap gap-2">
              <button
                onClick={() => handleFarmChange("all")}
                className={`px-4 py-2 rounded-md transition-colors ${
                  selectedFarm === "all"
                    ? "bg-gray-200 text-gray-800 font-semibold"
                    : "bg-white text-gray-600 hover:bg-gray-100"
                } border border-gray-300`}
              >
                {t("herdCounting.allFarms")}
              </button>
              {farms.map((farm) => (
                <button
                  key={farm.id}
                  onClick={() => handleFarmChange(farm.id)}
                  className={`px-4 py-2 rounded-md transition-colors ${
                    selectedFarm === farm.id
                      ? "bg-gray-200 text-gray-800 font-semibold"
                      : "bg-white text-gray-600 hover:bg-gray-100"
                  } border border-gray-300`}
                >
                  {farm.name}
                </button>
              ))}
            </div>
          </div>
        )}

        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort("timestamp")}
                  >
                    {t("herdCounting.countingDate")}{" "}
                    {sortField === "date" &&
                      (sortDirection === "asc" ? (
                        <FaSortAmountUp className="inline" />
                      ) : (
                        <FaSortAmountDown className="inline" />
                      ))}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer"
                    onClick={() => handleSort("animals")}
                  >
                    {t("herdCounting.numberOfAnimals")}{" "}
                    {sortField === "animals" &&
                      (sortDirection === "asc" ? (
                        <FaSortAmountUp className="inline" />
                      ) : (
                        <FaSortAmountDown className="inline" />
                      ))}
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("herdCounting.direction")}
                  </th>
                  {farms.length > 1 && (
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {t("herdCounting.farm")}
                    </th>
                  )}
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {t("herdCounting.video")}
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {counts.map((count, index) => (
                  <tr
                    key={count._id}
                    className={index % 2 === 0 ? "bg-white" : "bg-gray-50"}
                    ref={
                      index === counts.length - 1 ? lastCountElementRef : null
                    }
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <span>
                          {format(
                            new Date(count.timestamp),
                            "yyyy-MM-dd HH:mm"
                          )}
                        </span>
                        {isNew(count.timestamp) && (
                          <span className="ml-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-blue-100 text-blue-800">
                            {t("common.new")}
                          </span>
                        )}
                      </div>
                      <div className="text-sm text-gray-500">
                        {formatDistanceToNow(new Date(count.timestamp), {
                          addSuffix: true,
                          locale: dateLocale,
                        })}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {renderAnimalCount(count.animals)}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {getCountingDirection(
                        count.countingDirection,
                        count.farmId
                      )}
                    </td>
                    {farms.length > 1 && (
                      <td className="px-6 py-4 whitespace-nowrap">
                        {getFarmName(count.farmId)}
                      </td>
                    )}
                    <td className="px-6 py-4 whitespace-nowrap">
                      <button
                        onClick={() => handleWatchClick(count)}
                        className={`${
                          count.videoKey === null
                            ? "text-gray-600 hover:text-gray-900"
                            : "text-blue-600 hover:text-blue-900"
                        }`}
                      >
                        <FaPlay className="inline mr-1" /> {t("common.watch")}
                      </button>
                    </td>
                  </tr>
                ))}
                {loading &&
                  [...Array(5)].map((_, index) => <SkeletonRow key={index} />)}
              </tbody>
            </table>
          </div>
        </div>
      </main>

      {showVideo && (
        <VideoModal
          videoUrl={currentVideo}
          notes={currentNotes}
          onClose={() => setShowVideo(false)}
        />
      )}
    </div>
  );
};

export default HerdTrackingDetail;
