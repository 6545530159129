import React from "react";
import * as FaIcons from "react-icons/fa";

const defaultIcons = {
  success: FaIcons.FaCheckCircle,
  info: FaIcons.FaInfoCircle,
  warning: FaIcons.FaExclamationTriangle,
  error: FaIcons.FaExclamationCircle,
};

const colors = {
  success: "from-green-400 to-green-600",
  info: "from-blue-400 to-blue-600",
  warning: "from-yellow-400 to-yellow-600",
  error: "from-red-400 to-red-600",
};

const Notification = ({ notification, onClose }) => {
  let IconComponent = null;

  if (notification.icon === undefined) {
    // Use default icon based on type
    IconComponent = defaultIcons[notification.type] || FaIcons.FaInfoCircle;
  } else if (notification.icon !== null) {
    // Use custom icon
    IconComponent = FaIcons[notification.icon] || FaIcons.FaInfoCircle;
  }

  return (
    <div
      className={`
        bg-gradient-to-br ${
          colors[notification.type] || "from-gray-400 to-gray-600"
        }
        text-white p-4 rounded-lg flex items-start
        backdrop-filter backdrop-blur-lg bg-opacity-90
        border-2 border-white border-opacity-20
        w-full max-w-xl mx-auto
        transform transition-all duration-300 ease-out
      `}
    >
      {IconComponent && <IconComponent className="flex-shrink-0 mr-4 mt-1" />}
      <div
        className={`flex-grow overflow-auto max-h-48 ${
          !IconComponent ? "ml-0" : ""
        }`}
      >
        {notification.title && (
          <h4 className="font-bold">{notification.title}</h4>
        )}
        <p>{notification.message}</p>
        {notification.content && (
          <div className="mt-2">{notification.content}</div>
        )}
      </div>
      <button
        onClick={onClose}
        className="flex-shrink-0 ml-4 text-white hover:text-gray-200 transition-colors duration-200"
      >
        <FaIcons.FaTimesCircle />
      </button>
    </div>
  );
};

export default Notification;
