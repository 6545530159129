import axios from "axios";
import Cookies from "js-cookie";

const API_URL = "https://api.agroaivision.com";

export const login = async (username, password) => {
  const response = await axios.post(`${API_URL}/auth/login`, {
    username,
    password,
  });
  if (response.status === 200) {
    const { token, expires_timestamp } = response.data;
    return { token, expires_timestamp };
  } else {
    return null;
  }
};

export const setAuthToken = (token, expiresTimestamp, rememberMe) => {
  if (rememberMe) {
    // Set a persistent cookie that expires at the specified timestamp
    Cookies.set("auth_token", token, {
      expires: new Date(expiresTimestamp),
      secure: true,
      sameSite: "strict",
    });
  } else {
    // Set a session cookie that expires when the browser is closed
    Cookies.set("auth_token", token, { secure: true, sameSite: "strict" });
  }
};

export const getAuthToken = () => {
  return Cookies.get("auth_token");
};

export const removeAuthToken = () => {
  Cookies.remove("auth_token");
};

export const isAuthenticated = () => {
  return !!getAuthToken();
};
