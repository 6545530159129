import React, { useState, useEffect, useRef } from "react";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { login, setAuthToken } from "../services/auth";
import { useNotification } from "../context/NotificationContext";
import { FaInfoCircle, FaCheckCircle, FaEye, FaEyeSlash } from "react-icons/fa";
import { getAuthToken } from "../services/auth";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({ username: false, password: false });
  const [passwordLengthValid, setPasswordLengthValid] = useState(false);
  const [passwordComplexityValid, setPasswordComplexityValid] = useState(false);
  const [showPasswordErrors, setShowPasswordErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const passwordTimerRef = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { addNotification } = useNotification();

  useEffect(() => {
    const checkSession = () => {
      const token = getAuthToken();
      if (token) {
        navigate("/projects");
      }
    };

    checkSession();
  }, [navigate]);

  const validatePassword = (pass) => {
    const isLengthValid = pass.length >= 8;
    const isComplexityValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/.test(pass);
    setPasswordLengthValid(isLengthValid);
    setPasswordComplexityValid(isComplexityValid);
    return isLengthValid && isComplexityValid;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    validatePassword(newPassword);
    setErrors({ ...errors, password: false });

    if (passwordTimerRef.current) {
      clearTimeout(passwordTimerRef.current);
    }

    passwordTimerRef.current = setTimeout(() => {
      setShowPasswordErrors(true);
    }, 400);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {
      username: !username.trim(),
      password: !password.trim(),
    };
    setErrors(newErrors);

    if (newErrors.username || newErrors.password) {
      addNotification({
        type: "error",
        title: t("login.errorTitle"),
        message: t("login.emptyFieldsError"),
        position: "top",
        timeout: 5000,
      });
      return;
    }

    if (!validatePassword(password)) {
      addNotification({
        type: "error",
        title: t("login.errorTitle"),
        message: t("login.invalidPasswordError"),
        position: "top",
        timeout: 5000,
      });
      return;
    }

    try {
      const { token, expires_timestamp } = await login(username, password);
      setAuthToken(token, expires_timestamp, rememberMe);

      addNotification({
        type: "success",
        title: t("login.success.title"),
        message: t("login.success.message"),
        position: "top",
        timeout: 2000,
      });

      setTimeout(() => {
        navigate("/projects");
      }, 1000);
    } catch (error) {
      console.error("Login failed:", error);
      addNotification({
        type: "error",
        title: t("login.errorTitle"),
        message: t("login.errorMessage"),
        position: "top",
        timeout: 5000,
      });
    }
  };

  return (
    <div>
      <Navbar
        title="Agro Ai Vision"
        showBackButton={false}
        showLogoutButton={false}
      />
      <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8 overflow-hidden">
        <div className="max-w-md w-full space-y-8">
          <div>
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {t("login.title")}
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              {t("login.subtitle")}
            </p>
          </div>
          <form className="mt-8 space-y-3" onSubmit={handleSubmit} noValidate>
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="username" className="sr-only">
                  {t("login.username")}
                </label>
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  required
                  className={`appearance-none rounded-md relative block w-full px-3 py-2 border ${
                    errors.username
                      ? "border-red-500 animate-shake"
                      : "border-gray-300"
                  } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm`}
                  placeholder={t("login.username")}
                  value={username}
                  onChange={(e) => {
                    setUsername(e.target.value);
                    setErrors({ ...errors, username: false });
                  }}
                />
              </div>
            </div>
            <div className="relative">
              <label htmlFor="password" className="sr-only">
                {t("login.password")}
              </label>
              <input
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                required
                className={`appearance-none rounded-md relative block w-full px-3 py-2 border ${
                  errors.password
                    ? "border-red-500 animate-shake"
                    : "border-gray-300"
                } placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm pr-10`}
                placeholder={t("login.password")}
                value={password}
                onChange={handlePasswordChange}
              />
              <button
                type="button"
                className="absolute right-0 top-0 bottom-0 px-3 flex items-center justify-center"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <FaEyeSlash className="h-5 w-5 text-gray-400" />
                ) : (
                  <FaEye className="h-5 w-5 text-gray-400" />
                )}
              </button>
            </div>
            <div className="mt-1 text-xs space-y-1">
              <p
                className={`${
                  passwordLengthValid
                    ? "text-green-500"
                    : showPasswordErrors
                    ? "text-red-500"
                    : "text-gray-500"
                }`}
              >
                {passwordLengthValid ? (
                  <FaCheckCircle className="inline mr-1" />
                ) : (
                  <FaInfoCircle className="inline mr-1" />
                )}
                {t("login.passwordLength")}
              </p>
              <p
                className={`${
                  passwordComplexityValid
                    ? "text-green-500"
                    : showPasswordErrors
                    ? "text-red-500"
                    : "text-gray-500"
                }`}
              >
                {passwordComplexityValid ? (
                  <FaCheckCircle className="inline mr-1" />
                ) : (
                  <FaInfoCircle className="inline mr-1" />
                )}
                {t("login.passwordComplexity")}
              </p>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="remember-me"
                  name="remember-me"
                  type="checkbox"
                  className="h-4 w-4 text-green-600 focus:ring-green-500 border-gray-300 rounded transition-all duration-200 ease-in-out transform hover:scale-110"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <label
                  htmlFor="remember-me"
                  className="ml-2 block text-sm text-gray-900"
                >
                  {t("login.rememberMe")}
                </label>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg
                    className="h-5 w-5 text-green-500 group-hover:text-green-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
                {t("login.submit")}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
